<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout
        column
        align-center
      >
        <img
          src="../assets/logo.png"
          alt="Home Page"
          class="mb-5"
        >
        <blockquote>
          &#8220;Welcome to {{ schoolName }} Dashboard Application.&#8221;
          <footer>
            <small>
              <em>&mdash;{{ schoolName }}</em>
            </small>
          </footer>
        </blockquote>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: "Home",
  computed: {
    ...mapState([
      'schoolName',
    ]),
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
